<template>
    <div>
        <h2> {{ formation.name }} </h2>
        <img :src="logoSrc()" :alt="formation.name" class="formation-logo"><h2> {{ formation.location }} </h2>
        <h3> from {{ formation.startDate | dateFormat('MM/YYYY') }} to {{ formation.endDate | dateFormat('MM/YYYY') }} </h3>
    </div>
</template>

<script>
export default {
    name: 'formation',
    props: ['formation'],

    methods: {
        logoSrc() {
            const url = require(`../assets/img/${this.formation.logoSrc}`);
            return url;
        }
    }
}
</script>

<style lang="scss">
    img.formation-logo {
        height: 150px !important;
    }
</style>