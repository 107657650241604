<template>
  <div class="project-container">
    <img :src="logoSrc()" :alt="project.name" class="project-logo" />
    <a :href="project.link" target="_blank"><h2 class="project-name">{{ project.name }}</h2></a>
    <h5 class="project-description">{{ project.description }}</h5>
  </div>
</template>

<script>
export default {
  props: ['project'],

  methods: {
    logoSrc() {
      const url = require(`../assets/img/${this.project.logoSrc}`);
      return url;
    }
  }
}
</script>

<style>
project-container {
  display: flex;
}

project-name {
  font-size: large;
}

project-description {
  font-size: medium;
}

img.project-logo {
  height: 150px !important;
}
</style>