<template>
    <div id="skills-container">
        <Skill v-for="skill in skills" :skill="skill" :key="skill.name" />
    </div>
</template>

<script>
import Skill from './Skill';

export default {
    name: 'Skills',
    props: ['skills'],
    components: {
        Skill
    }
}
</script>

<style>
    #skills-container {
        width: 50%;
        margin: 0 auto;
    }
</style>