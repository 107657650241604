import Vue from 'vue'
import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBirthdayCake, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import 'fullpage.js/vendors/scrolloverflow'
import VueFullPage from 'vue-fullpage.js/dist/vue-fullpage';

import VueFilterDateFormat from 'vue-filter-date-format';

import BootstrapVue from 'bootstrap-vue';
import CoreuiVue from '@coreui/vue';

import './assets/styles/animations.scss';

Vue.use(CoreuiVue);

Vue.use(BootstrapVue);
Vue.use(VueFullPage)
Vue.use(VueFilterDateFormat);


library.add(faBirthdayCake);
library.add(faChevronLeft);
library.add(faChevronRight);

Vue.component('font-awesome-icon', FontAwesomeIcon);

// Vue.config.productionTip = false

new Vue({
  el: '#app',
  render: h => h(App),
}).$mount('#app')