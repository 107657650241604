<template>
    <div>
        <h2> {{ experience.name }} </h2>
        <img :src="logoSrc()" :alt="experience.name" class="experience-logo"><h2> {{ experience.location }} </h2>
        <h3> from {{ experience.startDate | dateFormat('MM/YYYY') }} to <span v-if="experience.endDate">{{experience.endDate | dateFormat('MM/YYYY')}}</span><span v-else>present</span></h3>
    </div>
</template>

<script>
export default {
    name: 'experience',
    props: ['experience'],

    methods: {
        logoSrc() {
            const url = require(`../assets/img/${this.experience.logoSrc}`);
            return url;
        }
    }
}
</script>

<style lang="scss">
    img.experience-logo {
        height: 150px !important;
    }
</style>