export const cv = {
    formation: [
        {
            name: 'DEUST IOSI',
            location: 'UPHF - ISTV',
            startDate: new Date(2017, 8, 1, 0, 0, 0, 0),
            endDate: new Date(2019, 5, 1, 0, 0, 0),
            logoSrc: 'uphf.png',
            link: 'www.exemple.com'
        },
        {
            name: 'LPRO SIO',
            location: 'UPHF - ISTV',
            startDate: new Date(2019, 8, 1, 0, 0, 0, 0),
            endDate: new Date(2020, 5, 1, 0, 0, 0),
            logoSrc: 'uphf.png',
            link: 'www.exemple.com'
        }
    ],
    experience: [
        {
            name: 'Web Developer (Intern)',
            location: 'Circus Advertising',
            startDate: new Date(2019, 2, 25, 0, 0, 0, 0),
            endDate: new Date(2019, 5, 12, 0, 0, 0),
            logoSrc: 'circus.png',
        },
        {
            name: 'Software Developer (Intern)',
            location: 'Eclosia',
            startDate: new Date(2020, 2, 25, 0, 0, 0, 0),
            endDate: new Date(2020, 7, 12, 0, 0, 0),
            logoSrc: 'eclosia.png',
        },
        {
            name: 'Fullstack Developer',
            location: 'Polyconseil',
            startDate: new Date(2021, 2, 1, 0, 0, 0, 0),
            logoSrc: 'polyconseil.png',
        }
    ],
    project: [
        {
            name: "GifParty.net",
            description: "If you want to dance to your music with other people.",
            logoSrc: "gifparty.svg",
            link: "https://gifparty.net"
        }
    ],
    infos: {
        firstName: 'Valentin',
        lastName: 'Charoux',
        jobTitle: 'Software Developer',
        dateOfBirth: new Date(1999, 11, 20, 0, 0, 0, 0),
        email: 'contact@valentincharoux.com',
        linkedIn: 'https://www.linkedin.com/in/valentin-charoux/',
        address: [
            {
                city: 'Quatre Bornes',
                country: 'Mauritius',
                phoneNumber: '57174487',
            },
            {
                city: 'Aulnoy Lez Valenciennes',
                country: 'France',
                phoneNumber: '0767027764',
            },
        ]
    },
    skills: [
        {
            name: 'JS',
            image: 'javascript.png',
        },
        {
            name: 'Typescript',
            image: 'typescript.png',
        },
        {
            name: 'NodeJs',
            image: 'node.png',
        },
        {
            name: 'VueJs',
            image: 'vuejs.png',
        },
        {
            name: 'ReactJs',
            image: 'react.png',
        },
        {
            name: 'Bootstrap',
            image: 'bootstrap.png',
        },
        {
            name: 'ChartJs',
            image: 'chartjs.svg',
        },
        {
            name: 'CSS',
            image: 'css.png',
        },
        {
            name: 'HTML',
            image: 'html.png',
        },
        {
            name: 'C++',
            image: 'cpp.png',
        },
        {
            name: 'C',
            image: 'c.png',
        },
        {
            name: 'JAVA',
            image: 'java.png',
        },
    ]
}