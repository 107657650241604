<template>
    <div style="display: inline">
        <b-avatar class="skill-icon" size="3rem" v-if="skill.image == null"> {{skill.name}} </b-avatar>
        <img class="skill-icon" size="3rem" :src="getImageSrc()" v-else />
    </div>
</template>

<script>
export default {
    props: ['skill'],
    name: 'Skill',
    methods: {
        getImageSrc() {
            const url = require(`../assets/img/${this.skill.image}`);
            return url;
        }
    }
}
</script>

<style lang="scss">
    .skill-icon {
        margin: 0.25em;
        // font-size: 8pt;
        width: 2.8em;
    }
</style>