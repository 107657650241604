<template>
  <div id="app">
    <BackgroundCanvas />
    <full-page :options="options" id="fullpage">
      <div class="section" id="section-header">
        <div class="section-container">
          <img src="./assets/img/avatar.png" alt="logo" id="logo">
          <h1>{{ fullName }}</h1>
          <h2>{{ jobTitle }}</h2>
          <h3 class="scroll">Scroll down</h3>
        </div>
      </div>
      <div class="section" id="section-header">
        <div class="section-container">
          <Intro :cv="{age: age()}" />
        </div>
      </div>
      <div class="section">
        <div class="section-container">
          <ul id="info-list">
            <li><label><a href="/CV.pdf" target="_blank">My CV 📁</a></label></li>
            <li><label>Email:</label> <a :href="'mailto:' + email">{{ email }}</a></li>
            <li><label><a :href="linkedIn" target="_blank" rel="noopener noreferrer">LinkedIn</a></label></li>
            <li><label>Age:</label> {{ age() }} <font-awesome-icon icon="birthday-cake" v-if="isMyBirthDay()" /></li>
          </ul>
          <Skills :skills="skills" />
          <codersrank-widget username="valen20chx" class="my-widget"></codersrank-widget>
        </div>
      </div>
      <div class="section">
        <div class="section-container">
          <h1>Formation</h1>
          <div class="glide" id="formation-glider">
            <div class="glide__track" data-glide-el="track">
              <ul class="glide__slides">
                <li class="glide__slide" v-for="formation in formations" :key="formation.name">
                  <Formation :formation="formation" />
                </li>
              </ul>
            </div>
            <div data-glide-el="controls" v-if="formations.length > 1">
              <b-button pill class="glide__arrow glide__arrow--left slider-button-prev" data-glide-dir="<"><font-awesome-icon icon="chevron-left" /></b-button>
              <b-button pill class="glide__arrow glide__arrow--right slider-button-next" data-glide-dir=">"><font-awesome-icon icon="chevron-right" /></b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="section align-middle">
        <div class="section-container">
          <h1>Experience</h1>
          <div class="glide" id="experience-glider">
            <div class="glide__track" data-glide-el="track">
              <ul class="glide__slides">
                <li class="glide__slide" v-for="experience in experiences" :key="experience.name">
                  <Experience :experience="experience" />
                </li>
              </ul>
            </div>
            <div data-glide-el="controls" v-if="experiences.length > 1">
              <b-button pill class="glide__arrow glide__arrow--left slider-button-prev" data-glide-dir="<"><font-awesome-icon icon="chevron-left" /></b-button>
              <b-button pill class="glide__arrow glide__arrow--right slider-button-next" data-glide-dir=">"><font-awesome-icon icon="chevron-right" /></b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="section align-middle">
        <div class="section-container">
          <h1>Projects</h1>
          <div class="content-section">
            <div class="glide" id="experience-glider">
            <div class="glide__track" data-glide-el="track">
              <ul class="glide__slides">
                <li class="glide__slide" v-for="project in projects" :key="project.name">
                  <Project :project="project"/>
                </li>
              </ul>
            </div>
            <div data-glide-el="controls" v-if="projects.length > 1">
              <b-button pill class="glide__arrow glide__arrow--left slider-button-prev" data-glide-dir="<"><font-awesome-icon icon="chevron-left" /></b-button>
              <b-button pill class="glide__arrow glide__arrow--right slider-button-next" data-glide-dir=">"><font-awesome-icon icon="chevron-right" /></b-button>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="section align-middle">
        <div class="section-container">
          <h1>Thanks for visiting<br>
          👋</h1>
        </div>
      </div>
    </full-page>
  </div>
</template>

<script>
import { cv } from './assets/data/cv';
import Experience from './components/Experience';
import Formation from './components/Formation';

import Glide from '@glidejs/glide';
import Skills from './components/Skills';
import Intro from './components/Intro';

import BackgroundCanvas from './components/BackgroundCanvas';
import Project from './components/Project.vue';

var currentSectionIndex = 0;

export default {
  name: 'App',
  components: {
    Formation,
    Experience,
    Skills,
    BackgroundCanvas,
    Intro,
    Project
},
  data: () => {
    return {
      jobTitle: cv.infos.jobTitle,
      fullName: `${cv.infos.firstName} ${cv.infos.lastName}`,
      email: cv.infos.email,
      linkedIn: cv.infos.linkedIn,
      age: () => {
        const dob = cv.infos.dateOfBirth;
        const now = new Date();

        return (now.getUTCFullYear() - dob.getUTCFullYear() - 1) + ((now.getUTCMonth() == dob.getUTCMonth() && now.getUTCDate() >= dob.getUTCDate()) ? 1 : 0);
      },
      skills: cv.skills,
      formations: cv.formation.reverse(),
      experiences: cv.experience.reverse(),
      projects: cv.project.reverse(),
      options: {
        licenseKey: 'YOUR_KEY_HERE',
        scrollOverflow: true,
        scrollBar: false,
        afterLoad: (origin, destination) => {
          currentSectionIndex = destination.index;
        }
      },
    };
  },

  mounted() {
    new Glide('#formation-glider').mount();
    new Glide('#experience-glider').mount();

    function sendMousePos(e) {

      const pos = {
        x: e.clientX,
        y: e.clientY + window.innerHeight,
        section: currentSectionIndex 
      };
      pos;
    }

    console.log(this.experiances);

    window.addEventListener('mousemove', sendMousePos);
  },

  methods: {
    isMyBirthDay() {
      const dob = cv.infos.dateOfBirth;
      const now = new Date();

      if(now.getMonth() == dob.getMonth() && now.getDate() == dob.getDate()) {
        return true;
      }

      return false;
    }
  },

  sendMousePos(e) {

    const pos = {
      x: e.clientX,
      y: e.clientY
    };
    pos;
  }
}
</script>

<style lang="scss">
@import "../node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|Roboto:100,300,400");
// @import "~@coreui/coreui/scss/coreui";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  text-align: center;
  color: #000;
  font-family: 'Montserrat', 'Roboto';

  img#logo {
    width: 250px
  }

  #section-header {
    text-align: center;
  }

  ul#info-list {
    list-style: none;
    right: 50%;
    &>li {
      text-align: left;
      &>label {
        font-weight: bold;
      }
    }
  }

  .slider-button-prev {
    margin-left: 5em;
    float: left;
  }
  .slider-button-next {
    margin-right: 5em;
    float: right;
  }

  .section-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .my-widget {
    --bg-color: #111;
    --header-bg-color: #222;
    --badge-bg-color: #333;
    --badge-text-color: #fff;
    width: 15em;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 1em;
  }

  .scroll {
    margin-top: 5em;
    -webkit-animation: floating-text 2s ease alternate infinite;
      -moz-animation: floating-text 2s ease alternate infinite;
        -ms-animation: floating-text 2s ease alternate infinite;
        -o-animation: floating-text 2s ease alternate infinite;
            animation: floating-text 2s ease alternate infinite;
  }

}
</style>
