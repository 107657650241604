<template>
  <div id="background-canvas-container"></div>
</template>

<script>
import 'particles.js'

import snowParticles from '../assets/particlesjs-config-snow.json';
import defaultParticles from '../assets/particlesjs-config-default.json';

export default {
  name: "BackgroundCanvas",
  data: () => {
    return {};
  },
  mounted() {
    const today = new Date();
    let particlesConfig = defaultParticles;
    if([11, 0, 1].includes(today.getMonth())) { // During winter
      particlesConfig = snowParticles;
      this.$el.classList.add('night');
      document.getElementById('app').classList.add('night');
    } else {
      
      document.getElementById('app').classList.remove('night');
    }
    window.particlesJS("background-canvas-container", particlesConfig);
  },

  methods: {},
};
</script>

<style lang="scss">
#background-canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(#fef, #aba);
  &.night {
    background: radial-gradient(rgb(25, 27, 37), rgb(41, 41, 65));

  }
}

.night {
  color: #fff !important;
}
</style>